import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import css from "./ModalCamposRequeridosFicha.module.css"

const FIELDS = [
  { key: "fechaNacimiento", label: "Fecha de Nacimiento" },
  { key: "telefonoMovil", label: "Teléfono de contacto (fijo o móvil)" },
  { key: "dni", label: "DNI" },
  { key: "email", label: "Email" },
  { key: "codigoPostal", label: "Código Postal" },
  { key: "facturacion", label: "Datos para facturas" },
  { key: "condicionesUrl", label: "Firma de Condiciones" },
  { key: "rgpdurl", label: "Firma de RGPD" }
]

const EmptyStatus = () => (
  <i className={`material-icons ${css.iconClose}`}>close</i>
)

const ModalCamposRequeridosFicha = ({ open, alumno, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Ficha de {alumno.nombre}</DialogTitle>
    <DialogContent className={css.content}>
      <Table size="small">
        <TableHead>
          <TableCell>Campo</TableCell>
          <TableCell>Estado</TableCell>
        </TableHead>
        <TableBody>
          {FIELDS.map(({ key, label }) => (
            <TableRow key={key}>
              <TableCell>{label}</TableCell>
              <TableCell>
                {alumno[key] ? (
                  <i className={`material-icons ${css.iconOk}`}>check</i>
                ) : (
                  <EmptyStatus />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
)

export default ModalCamposRequeridosFicha
