import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import countries from './countries.json'
import css from './CountrySelector.module.css'

const getSelectedValue = (value)=> {
  if (!value) return null
  return countries.find(c=> c.name === value) || null
}

const CountrySelector = ({ 
  className, 
  name, 
  label, 
  value, 
  onChange 
})=> {

  const handleChange = (event, value)=> {
    if (value === null) return onChange(event, value)
    const country = countries.find(c=> c.name === value.name)
    if (!country) return onChange(event, '')
    else return onChange(event, country.name)
  }
  
  return (
    <Autocomplete
      id={name} 
      className={`${className} ${css.main}`}
      placeholder='Sin país seleccionado'
      options={countries}
      getOptionLabel={option=> `${option.emoji} ${option.name}`}
      value={getSelectedValue(value)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
        />
      )}
    />
  )
}

export default CountrySelector