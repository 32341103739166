import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import InformationTable from "../../../components/Table/InformationTable"
import TableHeader from "../../../components/TableHeader/TableHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import AlumnoHeader from "../../../components/AlumnoHeader/AlumnoHeader"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getValoracionesAlumno } from "../../../utils/api/alumnos"
import { dateToString, monthToString } from "../../../utils/date"
import useAlumno from "../../../hooks/useAlumno"
import css from "./Valoraciones.module.css"

const Valoracion = ({ valoracion }) => {
  const value = ["MUY_NEGATIVA", "NEGATIVA", "NEUTRAL", "POSITIVA", "MUY_POSITIVA"].indexOf(valoracion) + 1
  if (value) return (
    <>
      <span className={css.valoracion}>{value}</span>
      <span className={css.valoracionMax}>/5</span>
    </>
  )
  else return (
    <span className={css.placeholder}>Sin valoracion</span>
  )
}

const Valoraciones = () => {
  const notification = useNotification()
  const { id } = useAlumno()

  const [mes, setMes] = useState(monthToString(new Date()))

  const { isLoading, data: valoracionesList = [] } = useQuery({
    queryKey: ["valoracion-alumnos", "list", "alumno", id, mes],
    enabled: !!mes,
    queryFn: () =>
      getValoracionesAlumno({ alumno: id, mes })
        .then((datos) => datos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
        .catch((err) => {
          notification.error({ title: "Error al recuperar las valoraciones", content: err })
          return []
        }),
  })

  return (
    <PageLoading isLoading={isLoading}>
      <AlumnoHeader label='Valoraciones' />
      <TableHeader
        actions={
          <MesRecienteSelector
            name="mes"
            label="Mes"
            value={mes}
            backwardOptions="6"
            onChange={(e) => setMes(e.target.value)}
          />
        }
        title="Valoraciones"
        showSearch={false}
      />

      <InformationTable
        details={[
          { title: "Asignatura", key: "asignatura" },
          { title: "Profesor", key: "profesor" },
          { title: "Centro", key: "centro" },
          { title: "Fecha", key: "fecha" },
          { title: "Valoración clase", key: "valoracionAlumno" },
          { title: "Valoración profesor", key: "valoracionProfesor" },
          { title: "Observaciones", key: "observaciones", cell: css.observaciones },
        ]}
        data={valoracionesList.map((valoracion) => ({
          asignatura: `${valoracion.asignatura} (${valoracion.nivel})`,
          profesor: valoracion.profesor,
          centro: valoracion.centro,
          fecha: `${dateToString(valoracion.fechaAsistencia)} - ${valoracion.horaAsistencia}`,
          valoracionAlumno: <Valoracion valoracion={valoracion.valoracionClase} />,
          valoracionProfesor: <Valoracion valoracion={valoracion.valoracionProfesor} />,
          observaciones: valoracion.observaciones || "--",
        }))}
      />
    </PageLoading>
  )
}

export default Valoraciones
