import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography,  IconButton } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosMarketing.module.css'
import { getFlagEmoji } from '../../../../../utils/country'

const DatosMarketing = ({ 
  lead,
  onEdit,
 })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos de marketing</Typography>
          {isExpanded && (
            <IconButton className={css.button} size='small' color='secondary' onClick={handleEdit}>
              <i className='material-icons'>edit</i>
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='academia'
            label='Academia'
            value={lead.centro}
          />
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='pais'
            label='País'
            value={`${getFlagEmoji(lead.pais)} ${lead.pais}`}
          />
          <TextLabel
            className={css.field}
            name='codigoPostal'
            label='Código postal'
            value={lead.codigoPostal}
          />
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='comoConoce'
            label='Cómo nos ha conocido'
            value={lead.comoConoce}
          />
          <TextLabel
            className={css.field}
            name='objetivos'
            label='Objetivo'
            value={lead.objetivos}
          />
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='formaContacto'
            label='Forma de contacto'
            value={lead.formaContacto}
          />
          <TextLabel
            className={css.field}
            name='telefonoEntrante'
            label='Teléfono entrante'
            value={lead.telefonoEntrante}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosMarketing