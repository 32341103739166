import { GET, POST, PUT, DELETE } from '../request'

export const getAlumno = id => GET('/api/agora/alumnos/alumno', { id })
export const getAlumnoName = id => GET('/api/agora/alumnos/alumno/name', { id })
export const getAlumnoList = () => GET('/api/agora/alumnos/alumno/list')
export const getAlumnoListYear = periodo => GET(`/api/agora/alumnos/alumno/list/year`, { periodo })
export const getAlumnoListSearch = ({ filtro }) => GET('/api/agora/alumnos/alumno/list/search', { filtro })

export const createAlumno = (data = {}) => POST('/api/agora/alumnos/alumno', data)

export const updateFichaAlumno = ({
  id,
  nombre,
  apellidos,
  fechaNacimiento,
  dni,
  email,
  telefono,
  movil,
  codigoPostal,
  centro,
}) => PUT('/api/agora/alumnos/alumno', {
  id,
  nombre,
  apellidos,
  fechaNacimiento,
  dni,
  email,
  telefono,
  movil,
  codigoPostal,
  centro,
})

export const updateTutoresAlumno = ({
  id,
  tutor1Rol,
  tutor1Nombre,
  tutor1Email,
  tutor1Movil,
  tutor1DNI,
  tutor2Rol,
  tutor2Nombre,
  tutor2Email,
  tutor2Movil,
  tutor2DNI,
}) => PUT('/api/agora/alumnos/alumno/tutores', {
  id,
  tutor1Rol,
  tutor1Nombre,
  tutor1Email,
  tutor1Movil,
  tutor1DNI,
  tutor2Rol,
  tutor2Nombre,
  tutor2Email,
  tutor2Movil,
  tutor2DNI,
})

export const updateFacturacionAlumno = ({ id, nombre, identificacion }) => PUT('/api/agora/alumnos/alumno/facturacion', { id, nombre, identificacion })

export const getFotoAlumno = ({ alumno }) => GET('/api/agora/alumnos/foto-alumno', { alumno })
export const updateFotoAlumno = ({ alumno, foto }) => PUT('/api/agora/alumnos/foto-alumno', {}, { alumno, foto })

export const getCertificadosAlumno = (data = {}) => GET('/api/agora/alumnos/certificado-alumno/list/alumno', data)
export const createCertificadoAlumno = (data = {}) => POST('/api/agora/alumnos/certificado-alumno', data)

export const getNotasAlumnoList = alumno => GET('/api/agora/alumnos/notas-alumno/list', { alumno })
export const createNotaAlumno = ({ alumno, nota }) => POST('/api/agora/alumnos/notas-alumno', { alumno, nota })
export const deleteNotaAlumno = id => DELETE('/api/agora/alumnos/notas-alumno', { id })

export const getNotasProfesorList = alumno => GET('/api/agora/alumnos/notas-profesor/list', { alumno })

export const getTokenIdentificacion = id => GET('/api/agora/alumnos/alumno/identification-token', { id })


export const getValoracionesAlumno = ({ alumno, mes }) => GET('/api/agora/alumnos/valoracion-alumnos/list/alumno', { alumno, mes });