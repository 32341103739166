import { useState } from "react"
import { Chip } from "@mui/material"
import ModalCamposRequeridosFicha from "../../modals/ModalCamposRequeridosFicha/ModalCamposRequeridosFicha"
import css from "./ProgresoFicha.module.css"

const calcularProgreso = (alumno) => {
  if (!alumno) return 0
  const criteriosCompletos = [
    alumno.fechaNacimiento,
    alumno.dni,
    alumno.codigoPostal,
    alumno.email,
    alumno.telefonoFijo || alumno.telefonoMovil,
    alumno.facturacion?.identificacion && alumno.facturacion?.nombre,
    alumno.condicionesUrl,
    alumno.rgpdurl,
  ].filter(Boolean).length
  return Math.round((criteriosCompletos / 8) * 100)
}

const determinarEstado = (progreso) => {
  if (progreso < 50) return "ko"
  if (progreso < 85) return "warning"
  return "ok"
}

const ProgresoFicha = ({ alumno }) => {
  const [mostrarProgreso, setMostrarProgreso] = useState(false)

  const handleVerProgreso = () => {
    setMostrarProgreso(true)
  }

  const progreso = calcularProgreso(alumno)
  const estado = determinarEstado(progreso)

  const getColor = (estado) => {
    if (estado === "ko") return "error"
    if (estado === "warning") return "warning"
    return "primary"
  }

  return (
    <>
      <Chip
        className={`${css.chip} ${css.progress}`}
        icon={<i className="material-icons">assignment_ind</i>}
        label={`Progreso ficha ${progreso}%`}
        color={getColor(estado)}
        variant="outlined"
        onClick={handleVerProgreso}
      />
      <ModalCamposRequeridosFicha
        open={mostrarProgreso}
        alumno={alumno}
        onClose={() => setMostrarProgreso(false)}
      />
    </>
  )
}

export default ProgresoFicha
