import { Badge, Collapse, IconButton, Link, TableCell, TableRow, TextField } from "@mui/material"
import useResponsive from "../../../../hooks/useResponsive"
import { isBlank } from "../../../../utils/string"
import css from './FormacionRow.module.css'

const SinProfesor = ()=> (
  <span className={css.placeholder}>Sin profesor</span>
)

const FormacionRow = ({ asistencia, isOpen, onOpen })=> {

  const { size, isScreenBiggerThan } = useResponsive()

  const hasObservaciones = !isBlank(asistencia.observaciones)

  const getColspan = ()=> {
    if (size === 'xl') return 6
    if (size === 'lg') return 5
    if (size === 'md') return 4
    return 3
  }
  
  return (
    <>
      <TableRow className={css.formacionRow}>
        <TableCell className={css.mainRowCell}>
          <Link
            className={css.alumnoLink}
            href={`/formaciones/${asistencia.formacionId}/ficha`}
          >
            {asistencia.formacion}
          </Link>
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'} 
        >
          {asistencia.asignatura}
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'} 
        >
          {asistencia.profesor || <SinProfesor />}
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('lg') ? 'visible' : 'invisible'} 
        >
          Formación
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <i className='material-icons' data-asistencia='VIENE'>check</i>
        </TableCell>
        <TableCell className={css.mainRowCell}>
          <IconButton size="small" onClick={onOpen}>
            <Badge color="primary" variant="dot" invisible={!hasObservaciones}>
              <i className='material-icons'>
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </Badge>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={css.formacionRow}>
        <TableCell className={css.additionalRow} colSpan={getColspan()}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <p className={css.extraInfo}>
              <i className='material-icons'>school</i>
              <span>
                Formación de <strong>{asistencia.asignatura}</strong> de nivel <strong>{asistencia.nivel}</strong> ({asistencia.tipo})
              </span>
            </p>
            <div className={css.observaciones}>
              <TextField
                name='observaciones'
                label='Observaciones'
                variant='standard'
                margin='dense'
                value={asistencia.observaciones || ''}
                disabled
                maxRows={8}
                multiline
                fullWidth
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default FormacionRow